export const

    sensor = {
        no2: "NO2",
        no: "NO",
        o3: "O3",
        co2: "CO2",
        co: "CO",
        so2: "SO2",
        h2s: "H2S",
        nh3: "NH3",
        pm1: "PM1",
        pm2p5: "PM2P5",
        pm4: "PM4",
        pm10: "PM10",
        voc_iaq: "VOC_IAQ",
        noise: "noise",
        temperature: "temperature",
        rh: "relativeHumidity",
        ah: "absoluteHumidity",
        temperature_meteo: "temperatureMeteo",
        rh_meteo: "relativeHumidityMeteo",
        pressure: "pressure",
        equivalent_pressure: "equivalentPressure",
        rain_rate: "rainRate",
        solar_radiation: "solarRadiation",
        uv_index: "uvIndex",
        wind: "wind",
        wind_speed: "windSpeed",
        wind_gust: "windGust",
        wind_direction: "wdirection",
        battery: "battery",
        voc: "VOC",
        aqi: "aqi",
    },

    indexTypes = {
        AQI: 'AQI',
        EAQI: 'EAQI',
        ICQA: 'ICQA',
        DIBA: 'DIBA',
        ISPU: 'ISPU',
        AQHI: 'AQHI',
        APIMS: 'APIMS',
        ICA: 'ICA',
        "AQI-QB": 'AQI-QB',
        DAQI: 'DAQI',
        "AQI-CH": 'AQI-CH',
    },

    CATEGORICAL_INDEXES = [indexTypes.EAQI, indexTypes.ICQA, indexTypes.DIBA],

    airQualityIndexColors = {
        AQI: ["#52B946","#F3EC18","#F67D1E","#ED1C25",
            "#7D287C","#480D27"],
        EAQI: ["#73e7df","#4fc5a5","#e7de00","#f55050",
            "#920434","#7b227e"],
        ISPU:["#31c535","#026ebb","#f5b900","#f70000",
            "#030303"],
        ICQA:["#399dc7","#319c5c","#e9dc4a","#c23442",
            "#6b181f","#9f5aa0"],
        DIBA:["#05aae7","#8dc950","#f5f513","#f50309"],
        AQHI:["#3dc4ee","#f9c931","#e8202b","#600f11"],
        APIMS:["#0000FF","#428500","#FFF833","#FD8800",
            "#D41413"],
        ICA: ["#96c66a", "#f1f162", "#ee7a53", "#e62c2e",
            "#a171a2", "#694434"],
        "AQI-QB": ["#96c66a", "#f1f162", "#ee5355"],
        DAQI: ["#00E400", "#F67D1E", "#FF0000", "#7D287C"],
        "AQI-CH": ["#52B946", "#F3EC18", "#F67D1E",
            "#ED1C25","#7D287C"]
    },

    indexMoreInfoUrl = {
        AQI: "https://www.airnow.gov/aqi/aqi-basics/",
        EAQI: "https://airindex.eea.europa.eu/AQI/index.html",
        ISPU: "https://ditppu.menlhk.go.id/portal/read/indeks-standar-pencemar-udara-ispu-sebagai-informasi-mutu-udara-ambien-di-indonesia",
        ICQA: "https://mediambient.gencat.cat/ca/05_ambits_dactuacio/atmosfera/qualitat_de_laire/avaluacio/icqa/index.html",
        DIBA: "https://www.diba.cat/mediambient",
        AQHI:"https://www.canada.ca/en/environment-climate-change/services/air-quality-health-index.html",
        APIMS:"https://apims.doe.gov.my/home.html",
        ICA: "https://www.minambiente.gov.co/wp-content/uploads/2021/10/Resolucion-2254-de-2017.pdf",
        "AQI-QB": "https://www.iqa.environnement.gouv.qc.ca/contenu/calcul_en.htm",
        DAQI: "https://uk-air.defra.gov.uk/air-pollution/daqi?view=more-info"
    },

    stationState = {
        active: "active",
        online: "online",
        offline: "offline"
    },

    MODEL_EXTERNAL_PREFIX = "EXT-",

    TEXT_COLOR          = "#484848",
    TEXT_COLOR_CONTRAST = "#FFFFFF",

    AQI_RANGE = ["currentMonth", "lastMonth", "last3Month"];

